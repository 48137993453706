<template>
    <div class="copy-wrapper o-flex o-flex--center">
        <div class="link-wrapper">
            {{ link }}
        </div>
        <a href="javascript:;" class="copy" @click="copyToClipboard">
            <svg width="24" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
            </svg>
        </a>
    </div>
</template>
<script>

export default {
    name:"CopyLink",
    emits: ['success', 'error'],
    props: {
        link: {
            type: String,
        }
    },
    methods: {
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.link)
                this.$emit('success', this.link)
            } catch (e) {
                this.$emit('error', e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .copy-wrapper {
        gap: 20px;
        background-color: $color-type3;
        height: 40px;
        border-radius: 5px;
        padding: 0 10px;
        width: 100%;

    }

    .link-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        color: $color-type16;

    }

    .copy {
        svg {
            pointer-events: none;
        }
        path:last-child {
            fill: $color-type28;
        }

        &:hover {
            path:last-child {
                fill: darken($color-type28, 10%);
            }
        }
    }
</style>