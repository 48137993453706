import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from "@/i18n";
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import it from '@vee-validate/i18n/dist/locale/it.json';
import {configure} from "vee-validate";
import axios from "axios";
import bootstrap from "@/bootstrap";
import { clickOutside } from "@/directives";
import * as Sentry from "@sentry/vue";
import { clearExpiredAnimatedUsers } from "@/service/animations";
import { getQueryParamValue } from "@/utilities/general";
import { availableLanguages } from "@/service/languages";
import "@/assets/styles/_shared.scss";
localize('it', it)
localize('en', en)
localize('de', de)
localize('fr', fr)

i18n.global.locale = availableLanguages().includes(getQueryParamValue('lang')) ? getQueryParamValue('lang') : null ?? availableLanguages()[0]
store.commit('authenticate/setLang', i18n.global.locale)

configure({
    generateMessage: localize(i18n.global.locale)
})

axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true;

bootstrap().then(() => {

    const app = createApp(App)

    if(process.env.VUE_APP_ENVIRONMENT !== 'local') {

        Sentry.init({
            app,
            environment: process.env.VUE_APP_ENVIRONMENT,
            release: `${process.env.VUE_APP_VERSION}`,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
                new Sentry.Replay({
                    maskAllText: false,
                    maskAllInputs: false
                })
            ],
            debug: process.env.VUE_APP_ENVIRONMENT  !==  'production',
            // Vue specific
            logErrors: process.env.VUE_APP_ENVIRONMENT !== 'local',
            attachProps: true,
            attachStacktrace: true,
        })

    }

    app.use(store).use(router).use(i18n).directive('click-outside', clickOutside).mount('#app')

    clearExpiredAnimatedUsers()

})


